<template>
    <section>
        <datos-basicos-futuro />
        <div v-show="id_cupon">
            <div class="row mx-0">
                <div class="col-8 bg-white px-3 py-3 br-8">
                    <div class="row mx-0">
                        <div class="col-3 text-general text-center f-17">
                            Tipo descuento
                        </div>
                        <div class="col-3 text-center">
                            <div class="d-middle-center my-1">
                                <input v-model="datosBasicos.descuento_tipo" :value="1" type="radio" class="option-input radio" @change="cambioTipoDescuento(1)" /> Descuento %
                            </div>
                            <el-input v-show="datosBasicos.descuento_tipo == 1"
                                      v-model="datosBasicos.descuento"
                                      size="medium" class="w-100"
                                      @input="cambioDescuento($event,'input')"
                                      @change="cambioDescuento($event,'change')"
                            >
                                <template slot="append">
                                    %
                                </template>
                            </el-input>
                            <p v-show="datosBasicos.descuento_tipo == 1 && errorDescuento" class="f-11 text-danger">El campo debe ser numerico</p>
                        </div>
                        <div class="col-3 text-center">
                            <div class="d-middle-center my-1">
                                <input v-model="datosBasicos.descuento_tipo" :value="2" type="radio" class="option-input radio" @change="cambioTipoDescuento(2)" /> Descuento valor
                            </div>
                            <el-input v-show="datosBasicos.descuento_tipo == 2"
                                      v-model="datosBasicos.descuento" size="medium" class="w-100"
                                      @input="cambioDescuento($event,'input')"
                                      @change="cambioDescuento($event,'change')"
                            >
                                <template slot="prepend">
                                    $
                                </template>
                            </el-input>
                            <p v-show="datosBasicos.descuento_tipo == 2 && errorDescuento" class="f-11 text-danger">El campo debe ser numerico</p>
                        </div>
                    </div>
                    <div class="row mx-0 my-4">
                        <div class="col-3 text-general d-middle-center text-center f-17">
                            Cantidad de cupones otorgados
                        </div>
                        <div class="col-3">
                            <el-input-number v-model="datosBasicos.hijo_cantidad" size="medium" class="w-100" :min="1" :controls="false" @change="cambiarCondiciones($event,'hijo_cantidad')" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-8 px-3 py-3 bg-white br-8">
                    <p class="f-17 text-general f-600">
                        Condiciones para seleccionar a los {{ datosBasicos.destinatario == 1 ? $config.cliente : $config.vendedor }}  automáticos
                    </p>
                    <ValidationObserver ref="condicionesUso" tag="div" class="row mx-0 align-items-center my-3">
                        <div class="col-3 text-general">
                            Cantidad de pedidos realizados
                        </div>
                        <ValidationProvider v-slot="{ errors, valid }" rules="numeric" tag="div" name="  " class="col-2">
                            <div class="d-middle">
                                <span class="mx-1">
                                    Entre
                                </span>
                                <el-input v-model="datosBasicos.pedidos_minimo" size="medium" @change="eventoCambiar($event,'pedidos_minimo', valid)" />
                            </div>
                            <p class="f-12 text-general-red"> {{ errors[0] }}</p>
                        </ValidationProvider>
                        <div class="col-auto px-0">
                            y
                        </div>
                        <ValidationProvider v-slot="{ errors, valid }" rules="numeric" tag="div" name="  " class="col-2">
                            <el-input v-model="datosBasicos.pedidos_maximo" size="medium" @change="eventoCambiar($event,'pedidos_maximo', valid)" />
                            <p class="f-12 text-general-red">{{ errors[0] }}</p>
                        </ValidationProvider>
                        <!-- <div class="col-2 d-middle">
                </div> -->
                    </ValidationObserver>
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-8 px-3 py-3 bg-white br-8">
                    <p class="text-general f-600 f-17">
                        Limites de uso del Bono
                    </p>
                    <div class="row mx-0 my-3">
                        <div class="col-3 text-general d-middle">
                            Valor mínimo de compra
                        </div>
                        <div class="col-3 pr-4">
                            <el-input-number v-model="datosBasicos.compra_minima" class="w-100" :controls="false" :min="0" @change="cambiarCondiciones($event,'compra_minima')" />
                        </div>
                    </div>
                    <div class="row mx-0 my-3">
                        <div class="col-3 text-general d-middle">
                            Descuento máximo aplicado
                        </div>
                        <div class="col-3 d-middle pr-0">
                            <el-input-number v-model="datosBasicos.descuento_maximo" class="w-100" :controls="false" :min="0" @change="cambiarCondiciones($event,'descuento_maximo')" />
                            <el-tooltip placement="bottom" content="Cuando se supere este valor, el descuento se aplicará a este máximo." effect="light">
                                <i class="icon-info-circled-alt text-general2 f-18" />
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4 d-middle">
                        <div class="col-3 text-general">
                            Vigencia (Dias)
                        </div>
                        <div class="col-3 pr-4">
                            <el-input-number v-model="datosBasicos.hijo_vigencia" class="w-100" :controls="false" :min="1" @change="cambiarCondiciones($event,'hijo_vigencia')" />
                        </div>
                    </div>
                    <div class="row mx-0 my-3">
                        <div class="col-12 text-general f-600 f-17">
                            Productos aplicados
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-12">
                            <el-checkbox v-model="datosBasicos.productos_oferta" :true-label="1" :false-label="0" class="check-red" @change="cambiarCondiciones($event,'productos_oferta')">
                                Incluir productos en oferta
                            </el-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <todo-catalogo :id-cupon="id_cupon" :datos-basicos="datosBasicos" />
        </div>
    </section>
</template>
<script>
import Cupones from '~/services/cupones/cupones'
import {mapGetters} from 'vuex'
export default {
    components: {
        datosBasicosFuturo:() => import('./datosBasicosFuturo'),
        todoCatalogo:() => import('./todoCatalogo'),
    },
    data(){
        return {

            errorDescuento:false,


            cupon: 1,
            nombreCupon: '',
            codigo: '',
            mensajeCupon: '',
            rangoFechas: [{
                value: 'Option1',
                label: 'Ultima Semana'
            }, {
                value: 'Option2',
                label: 'Ultimo Mes'
            }, {
                value: 'Option3',
                label: 'Ultimos Tres Meses'
            }, {
                value: 'Option4',
                label: 'Ultimo Año'
            }],
            value: '',
            anadirCedis: '',
            limiteCupon: '',
            descuentoValor: '',
            tipo: 1,
            limiteUso: '',
            limiteCliente: '',
            clientes_automaticos: '',
            checkList: ['selected and disabled','Option A'],
            valorMinimoCompra: '',
            fecha: '',
            categoria: '',
            productosOferta: false,
            todoCarrito: false
        }
    },
    computed:{
        ...mapGetters({
            datosBasicos: 'cupones/cupones/datosBasicosFuturo', //Datos del cupon hijo
        }),
        id_cupon_padre(){
            return Number(this.$route.params.id_cupon);
        },
        id_cupon(){
            return this.datosBasicos.id
        },

    },
    methods:{
        async cambioTipoDescuento(value){
            try {
                const {data} = await Cupones.cambioTipoDescuento(this.id_cupon,value)
                this.errorDescuento = false
                this.$store.commit('cupones/cupones/set_datos_basicos_futuro',data.cupon)

            } catch (e){
                this.error_catch(e)
            }
        },
        async cambioDescuento(value,tipo){
            if(tipo === 'input'){
                if(isNaN(value)){
                    this.errorDescuento = true
                }else{
                    this.errorDescuento = false
                }
                return
            }
            if(tipo === 'change' && !isNaN(value)){
                let form = {
                    id_cupon:this.id_cupon,
                    descuento:value
                }
                const {data} = await Cupones.cambioDescuento(this.id_cupon,form)
                this.$store.commit('cupones/cupones/set_datos_basicos_futuro',data.cupon)
            }
        },
        async cambiarCondiciones(value,campo){
            try {
                let form = {
                    columna:campo,
                    value
                }
                const {data} = await Cupones.cambiarCondiciones(this.id_cupon,form)
                this.$store.commit('cupones/cupones/set_datos_basicos_futuro',data.cupon)
            } catch (e){
                this.error_catch(e)
            }
        },
        eventoCambiar(value,campo, valid){
            if(!valid) return
            if(campo === 'pedidos_minimo'){
                if(this.datosBasicos.pedidos_maximo != null && this.datosBasicos.pedidos_maximo != "" && value){
                    if(value > this.datosBasicos.pedidos_maximo){
                        this.notificacion('Alerta','El campo minimo no puede ser mayor al maximo.','warning')
                        return
                    }
                }
            }

            if(campo === 'pedidos_maximo'){
                if(this.datosBasicos.pedidos_minimo != null && this.datosBasicos.pedidos_minimo != "" && value){
                    if(value < this.datosBasicos.pedidos_minimo){
                        this.notificacion('Alerta','El campo maximo no puede ser menor al minimo','warning')
                        return
                    }
                }
            }

            this.cambiarCondiciones(value,campo)
        }

    }
}
</script>
<style lang="scss" scoped>
.active-button{
    background-color: var(--color-general);
    color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
}
.inactive-button{
    background-color: #F5F5F5;
    color: #707070;
    box-shadow: 0px 3px 6px #00000029;
}
</style>
